<template>
  <div class="smp-loginPage">
    <div class="smp-loginPage-container">
      <div class="smp-loginPage-logoWrapper">
        <div class="smp-loginPage-logoContainer" />
      </div>

      <div
        class="flex justify-center mt5 ms3"
        v-if="loading">
        <fa-icon
          icon="spinner"
          spin />
      </div>

      <template v-if="!loading">
        <div
          class="smp-loginPage-localeMenuWrapper"
          v-if="showLocaleMenu">
          <locale-menu />
        </div>

        <alert
          v-if="message"
          class="mb4"
          :type="message.type"
          :message="message.content"
          @close="message = null" />

        <ValidationObserver
          ref="validationObserver">
          <form
            class="smp-form"
            @submit.prevent="login">
            <ValidationProvider
              tag="div"
              class="field"
              :name="$t('login.email')"
              v-slot="{ errors }"
              rules="email|required">
              <label>
                {{ $t('login.email') }}
                <span
                  v-if="errors.length > 0"
                  class="smp-validationError">
                  {{ errors[0] }}
                </span>
              </label>
              <input
                type="email"
                v-model="email">
            </ValidationProvider>

            <ValidationProvider
              tag="div"
              class="field"
              :name="$t('login.password')"
              v-slot="{ errors }"
              rules="required">
              <label>
                {{ $t('login.password') }}
                <span
                  v-if="errors.length > 0"
                  class="smp-validationError">
                  {{ errors[0] }}
                </span>
              </label>
              <input
                type="password"
                v-model="password">
            </ValidationProvider>

            <div class="mt4">
              <button
                class="smp-button smp-button-primary smp-button--fullWidth"
                @click.prevent="login">
                {{ $t('login.cta') }}
              </button>
            </div>
          </form>
        </ValidationObserver>
      </template>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { backendService } from '@/services/BackendService';
import { login } from '@/util/firebase';
import Alert from '@/components/Alert.vue';
import LocaleMenu from '@/components/LocaleMenu.vue';

export default {
  name: 'Login',
  data() {
    return {
      email: '',
      password: '',
      message: null,
      autoLoginToken: null,
      backUrl: null,
      loading: true,
    };
  },
  components: { Alert, LocaleMenu },
  computed: {
    ...mapGetters(['showLocaleMenu']),
  },
  created() {
    this.init();
  },
  methods: {
    async init() {
      await this.checkAndClearSession();
      if (this.autoLoginToken) {
        await this.tryAutoLogin();
      } else {
        this.loading = false;
      }
    },
    async login() {
      const validationResult = await this.$refs.validationObserver.validate();

      if (!validationResult) return;

      try {
        const { token } = await backendService.login(this.email, this.password);

        await login(token);
      } catch (e) {
        let message = e?.data?.message ?? 'generic_error';

        const messageKey = `generic.serverMessages.${message}`;
        if (this.$t(messageKey) !== messageKey) {
          message = this.$t(messageKey);
        }

        this.message = {
          type: 'error',
          content: message,
        };
      }
    },
    checkAndClearSession() {
      try {
        this.autoLoginToken = sessionStorage.getItem('smp-autoLoginToken');
        this.backUrl = sessionStorage.getItem('smp-loginBackUrl');
        sessionStorage.removeItem('smp-autoLoginToken');
        sessionStorage.removeItem('smp-loginBackUrl');
      } catch (e) {
        // noop, some browsers (e.g. some safari versions if in private mode) prevent session storage access
      }
    },
    async tryAutoLogin() {
      if (this.autoLoginToken) {
        await login(this.autoLoginToken);
        if (this.backUrl) {
          location.href = this.backUrl;
        }
      } else {
        this.loading = false;
      }
    },
  },
};
</script>

<style lang="less">
@import (reference) "~@/styles/base";

  .smp-loginPage {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;

    .smp-loginPage-container {
      padding: @space-4 @space-5;
      background: @color-mono-100;
      border-radius: 5px;
      box-shadow: 1px 1px 5px @color-mono-80;
      margin-top: -10vh;
      max-width: 90%;
      width: 400px;

      .smp-loginPage-logoWrapper {
        display: flex;
        justify-content: center;
        margin-bottom: @space-4;
      }

      .smp-loginPage-localeMenuWrapper {
        display: flex;
        justify-content: center;
        margin-bottom: @space-5;
      }

      .form {
        width: 300px;
        max-width: 100%;
      }
    }
  }
</style>
