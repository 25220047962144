<template>
  <transition
    name="fadeUp">
    <div
      v-if="visible"
      class="smp-alert"
      :class="[cssClass, {'smp-alert--closable': closable}]">
      <div
        v-if="withIcon"
        class="smp-alert-icon mr2">
        <fa-icon :icon="icon" />
      </div>
      <div class="smp-alert-message flex-auto">
        <slot v-if="!message" />
        <template v-else>
          {{ message }}
        </template>
      </div>
      <div
        v-if="closable"
        @click.prevent="close"
        class="smp-alert-closeCta">
        <fa-icon :icon="['fas', 'times']" />
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  props: {
    /**
     * Either message or a slot content is required.
     */
    message: {
      type: String,
      required: false,
      default: null,
    },
    type: {
      type: String,
      required: false,
      default: 'success',
    },
    closable: {
      type: Boolean,
      required: false,
      default: true,
    },
    withIcon: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
  data() {
    return {
      visible: true,
    };
  },
  computed: {
    normalizedType() {
      let type = 'success';
      if (this.type === 'danger' || this.type === 'error') type = 'error';
      if (this.type === 'warn' || this.type === 'warning') type = 'warning';
      if (this.type === 'info') type = 'info';

      return type;
    },
    cssClass() {
      return `smp-alert--${this.normalizedType}`;
    },
    icon() {
      switch (this.normalizedType) {
      case 'error':
        return ['fas', 'exclamation-triangle'];
      case 'warning':
        return ['fas', 'exclamation-triangle'];
      case 'info':
        return ['fas', 'info-circle'];
      default:
        return ['fas', 'check'];
      }
    },
  },
  methods: {
    close() {
      this.visible = false;
      this.$emit('close');
    },
  },
};
</script>

<style lang="less">
@import (reference) "~@/styles/base";

.smp-alert-color(@color) {
  color: @color;
  border-color: @color;

  a {
    color: @color !important;
    text-decoration: underline;

    &:hover {
      color: darken(@color, 10) !important;
    }
  }

  .smp-alert-closeCta {
    transition: all 250ms ease-in-out;

    &:hover {
      background: @color;
      color: @color-mono-100;
    }
  }
}

.smp-alert {
  padding: @space-2 @space-2;
  border: 1px solid @color-mono-20;
  border-radius: @border-radius;
  display: flex;
  align-items: flex-start;
  position: relative;
  overflow: hidden;

  &.smp-alert--inline {
    display: inline-flex;
  }

  &.smp-alert--closable {
    .smp-alert-message {
      padding-right: @space-4;
    }
  }

  .smp-alert-closeCta {
    cursor: pointer;
    position: absolute;
    top: 0;
    right: 0;
    padding: @space-2;
    height: 100%;
  }

  &.smp-alert--info {
    .smp-alert-color(@color-mono-50);
  }

  &.smp-alert--success {
    .smp-alert-color(@color-success);
  }

  &.smp-alert--warning {
    .smp-alert-color(@color-warn);
  }

  &.smp-alert--error {
    .smp-alert-color(@color-error);
  }
}
</style>
